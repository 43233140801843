import React from 'react';
import { Link } from 'react-router-dom';
import { FileModel } from '../../models';
import placheholderSignature from '../../assets/placeholder-signature.png';
import Image from '../ui/Image';

interface UploadSignatureFileProps {
  file: FileModel;
}

const SignatureFileCard: React.FC<UploadSignatureFileProps> = ({ file }) => {
  const { url, _id } = file;

  return (
    <Link to={`signatures/${_id}`}>
      <div className="bg-white my-8 p-4 shadow-md flex flex-col justify-center text-des-green hover:underline">
        <Image src={url || placheholderSignature} alt="signature" />
        <div className="mb-1 mt-auto text-center flex-grow">
          <span className="mb-0 mt-auto">{file.name || 'Desjardins'}</span>
        </div>
      </div>
    </Link>
  );
};

export default SignatureFileCard;
