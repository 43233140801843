import React, { useCallback } from 'react';
import { UseFilesActions } from '../../hooks/useFiles';
import { FileModel, FileTypes } from '../../models';
import placheholderSignature from '../../assets/placeholder-signature.png';
import UploadButton from '../ui/Buttons/UploadButton';
import Image from '../ui/Image';

interface UploadSignatureFileProps {
  file: FileModel;
  actions: UseFilesActions;
}

const UploadSignatureFile: React.FC<UploadSignatureFileProps> = ({
  file,
  actions,
}) => {
  const onUpload = useCallback(
    async ({ target }) => {
      const { files } = target;
      const formData = new FormData();
      formData.append('uploadedFile', files[0]);
      formData.append('type', FileTypes.Signature);
      actions.updateFile(formData, file._id);
    },
    [actions, file]
  );
  const { url, _id } = file;

  return (
    <div className="bg-white my-8 p-4 shadow-md flex flex-col justify-center">
      <Image src={url || placheholderSignature} alt="signature" />
      <div className="mb-4">
        <UploadButton
          onChange={onUpload}
          domId={`${_id}-signature`}
          loading={false}
        >
          {_id ? 'Remplacer la signature' : 'Téléverser une nouvelle signature'}
        </UploadButton>
      </div>
    </div>
  );
};

export default UploadSignatureFile;
