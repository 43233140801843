import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';

import { FileModel, SignatureData } from '../models';
import {
  deleteData,
  FetchingStatus,
  fetchWithToken,
  putData,
  withFormData,
} from '../utils';

export interface UseFilesActions {
  createFile: (formData: FormData) => Promise<FileModel | undefined>;
  updateFile: (formData: FormData, id: string) => void;
  updateFileData: (formData: SignatureData, id: string) => void;
  deleteFile: (id: string) => Promise<void>;
}

interface UseFiles {
  files: FileModel[];
  actions: UseFilesActions;
  status: string;
}

function useFiles(): UseFiles {
  const [status, setStatus] = useState<FetchingStatus>(FetchingStatus.Fetching);
  const [openSnackbar] = useSnackbar();

  const [files, setFiles] = useState<FileModel[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const filesResponse = await fetchWithToken(`/files/`);
        const fetchedFiles: FileModel[] = await filesResponse.json();
        setFiles(fetchedFiles);

        setStatus(FetchingStatus.Fetched);
      } catch (error) {
        openSnackbar('Une erreur a empêché la récupération des fichiers');
      }
      setStatus(FetchingStatus.Fetched);
    };
    getData();
  }, [setFiles]);

  const createFile = useCallback(
    async (formData: FormData) => {
      setStatus(FetchingStatus.Fetching);
      let newFile: FileModel | undefined;
      try {
        const response: Response = await withFormData(`/files/`, formData);
        if (!response.ok) {
          throw Error();
        }

        newFile = await response.json();
        openSnackbar(`Le fichier a bien été créé`);
        setFiles((previous) => previous.concat(newFile as FileModel));
      } catch (error) {
        openSnackbar('Une erreur a empêché la création du fichier');
      }

      setStatus(FetchingStatus.Fetched);
      return newFile;
    },
    [setStatus, openSnackbar]
  );

  const updateFile = useCallback(
    async (formData: FormData, id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        const response: Response = await withFormData(
          `/files/${id}`,
          formData,
          'PUT'
        );
        if (!response.ok) {
          throw Error();
        }

        const updatedFile: FileModel = await response.json();
        openSnackbar(`Le fichier a bien été sauvegardé`);
        setFiles((previous) =>
          previous.map((currentCategory) => {
            if (currentCategory._id === id) {
              return updatedFile;
            }
            return currentCategory;
          })
        );
      } catch (err) {
        console.error(err);
        openSnackbar('Une erreur a empêché la sauvegarde du fichier');
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar]
  );

  const updateFileData = useCallback(
    async (signatureData: SignatureData, id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        const updatedFile = await putData<FileModel>(
          `/files/${id}/data`,
          signatureData
        );

        openSnackbar(`Le fichier a bien été sauvegardé`);
        setFiles((previous) =>
          previous.map((currentCategory) => {
            if (currentCategory._id === id) {
              return updatedFile;
            }
            return currentCategory;
          })
        );
      } catch (err) {
        console.error(err);
        openSnackbar('Une erreur a empêché la sauvegarde du fichier');
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar]
  );

  const deleteFile = useCallback(
    async (id: string) => {
      setStatus(FetchingStatus.Fetching);
      try {
        await deleteData(`/files/${id}`);
        openSnackbar(`Le fichier a bien été effacé`);
        setFiles((previous) => previous.filter(({ _id }) => _id !== id));
      } catch (error) {
        openSnackbar(`Une erreur a empêché d'effacer le fichier`);
      }
      setStatus(FetchingStatus.Fetched);
    },
    [setStatus, openSnackbar, setFiles]
  );

  return {
    status,
    files,
    actions: {
      createFile,
      updateFile,
      updateFileData,
      deleteFile,
    },
  };
}

export default useFiles;
