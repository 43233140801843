import React from 'react';
import { useParams } from 'react-router-dom';
import H3 from '../components/ui/H3';
import { FetchingStatus } from '../utils';
import Previous from '../components/ui/Previous';
import EditSignatureForm from '../components/Files/EditSignatureForm';
import useFiles from '../hooks/useFiles';

const EditSignature: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { files, status, actions } = useFiles();

  const dataReady = status === FetchingStatus.Fetched;

  const signatureFile = files.find(({ _id }) => _id === id);

  return (
    <div>
      <Previous to="/files" />
      <H3>Édition de la signature</H3>

      {status === FetchingStatus.Fetching && <div>LOADING</div>}

      {dataReady && signatureFile && (
        <EditSignatureForm signature={signatureFile} actions={actions} />
      )}
    </div>
  );
};

export default EditSignature;
