import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UseFilesActions } from '../../hooks/useFiles';
import { FileTypes } from '../../models';
import placheholderSignature from '../../assets/placeholder-signature.png';
import UploadButton from '../ui/Buttons/UploadButton';
import Image from '../ui/Image';

interface NewSignatureFileProps {
  actions: UseFilesActions;
}

const NewSignatureFile: React.FC<NewSignatureFileProps> = ({ actions }) => {
  const history = useHistory();

  const onUpload = useCallback(
    async ({ target }) => {
      const { files } = target;
      const formData = new FormData();
      formData.append('uploadedFile', files[0]);
      formData.append('type', FileTypes.Signature);
      const createdSignature = await actions.createFile(formData);

      if (createdSignature?._id) {
        history.push(`/signatures/${createdSignature?._id}`);
      }
    },
    [actions, history]
  );

  return (
    <div className="bg-white my-8 p-4 shadow-md flex flex-col justify-center">
      <Image src={placheholderSignature} alt="signature" />
      <div className="mb-4">
        <UploadButton onChange={onUpload} domId="new-signature" loading={false}>
          Téléverser une nouvelle signature
        </UploadButton>
      </div>
    </div>
  );
};

export default NewSignatureFile;
