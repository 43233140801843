export interface SignatureData {
  name: string;
  securityUrl: string;
}

export interface FileData {
  url: string;
  type: string;
}

export interface FileModel extends FileData, SignatureData {
  _id: string;
}

export enum FileTypes {
  Faq = 'faq',
  GoodPractices = 'good-practices',
  Signature = 'signature',
}
