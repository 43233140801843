import React from 'react';
import { SignatureData } from '../../models';

interface SignaturePreviewProps {
  signature: SignatureData;
}

const SignaturePreview: React.FC<SignaturePreviewProps> = ({
  signature,
}: SignaturePreviewProps) => {
  const legalNote = `${
    signature.name ?? 'Desjardins'
  } n’envoie jamais de courriels à ses membres et à ses clients pour leur demander de fournir des informations confidentielles. De plus, il ne leur envoie jamais des courriels contenant des liens vers une page d’authentification ou qui exige l’entrée d’informations confidentielles. Questions ou préoccupations ? Visitez ${
    signature.securityUrl ?? 'https://desjardins.ca/securite'
  }`;

  return (
    <div className="p-4">
      <h1>Aperçu du texte de sécurité de la signature&nbsp;:</h1>
      <div
        style={{
          color: '#555555',
          fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
          lineHeight: 1.2,
          paddingTop: '10px',
          paddingRight: '10px',
          paddingBottom: '10px',
          paddingLeft: '10px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
            lineHeight: 1.2,
            color: '#555555',
            fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
          }}
        >
          <p
            style={{
              fontSize: '14px',
              lineHeight: 1.2,
              wordBreak: 'break-word',
              textAlign: 'center',
              margin: '0',
            }}
          >
            <span style={{ color: '#808080', fontSize: '11px' }}>
              {legalNote}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignaturePreview;
