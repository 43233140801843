import React from 'react';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  defaultValue?: string;
  placeholder?: string;
  register: any;
}

const Input: React.FC<InputProps> = ({
  name,
  defaultValue,
  register,
  placeholder,
  size,
}) => (
  <input
    className="border py-2 px-3 text-grey-darkest focus:outline-none focus:border-2 focus:border-des-green"
    name={name}
    defaultValue={defaultValue}
    ref={register}
    placeholder={placeholder}
    size={size}
  />
);

export default Input;
