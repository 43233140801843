import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import Input from '../ui/Input';
import Label from '../ui/Label';

import SubmitButton from '../ui/Buttons/SubmitButton';
import { FileModel, SignatureData } from '../../models';
import FormElementWrapper from '../ui/FormElementWrapper';
import ConfirmationModal from '../ui/ConfirmationModal';
import StandardButton from '../ui/Buttons/StandardButton';
import { UseFilesActions } from '../../hooks/useFiles';
import UploadSignatureFile from './UploadSignatureFile';
import SignaturePreview from './SignaturePreview';

interface EditSignatureFormProps {
  signature: FileModel;
  actions: UseFilesActions;
}

const EditSignatureForm: React.FC<EditSignatureFormProps> = ({
  signature,
  actions,
}) => {
  const history = useHistory();

  const { _id, name, securityUrl } = signature;

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      name,
      securityUrl,
    },
  });

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onDelete = useCallback(async () => {
    await actions.deleteFile(_id);
    history.replace('/files');
  }, [_id, history, actions]);

  const onSubmit = useCallback(
    (newData: SignatureData) => {
      actions.updateFileData(newData, _id);
    },
    [actions, _id]
  );

  const onDeleteConfirm = useCallback(() => {
    setDeleteConfirmOpen(false);
    onDelete();
  }, [onDelete, setDeleteConfirmOpen]);

  return (
    <div className=" ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col max-w-7xl">
          <div className=" flex w-full">
            <div className="w-4/12 max-w-2xl">
              <UploadSignatureFile file={signature} actions={actions} />
            </div>
            <div className=" pl-8 w-8/12 flex flex-col">
              <FormElementWrapper
                error={errors.name}
                errorMessage="Cette information est requise"
              >
                <Label>Nom de la signature</Label>
                <Input
                  name="name"
                  size={Math.max(25, signature.name?.length ?? 0)}
                  register={register({ required: true })}
                />
              </FormElementWrapper>

              <FormElementWrapper
                error={errors.securityUrl}
                errorMessage="Cette information est requise"
              >
                <Label>URL vers le texte de sécurité</Label>
                <Input
                  name="securityUrl"
                  register={register({ required: true })}
                  size={Math.max(50, signature.securityUrl?.length ?? 0)}
                />
              </FormElementWrapper>

              <SignaturePreview signature={watch(['name', 'securityUrl'])} />
            </div>
          </div>

          <div className="flex justify-between py-4 pr-4 ">
            <StandardButton
              secondary
              onClick={() => setDeleteConfirmOpen(true)}
            >
              Effacer la signature
            </StandardButton>
            <SubmitButton>Enregistrer</SubmitButton>
          </div>
          <ConfirmationModal
            onConfirm={onDeleteConfirm}
            onCancel={() => setDeleteConfirmOpen(false)}
            isOpen={deleteConfirmOpen}
            confirmText="Supprimer la signature"
          >
            <div> Voulez-vous vraiment supprimer cette signature ?</div>
          </ConfirmationModal>
        </div>
      </form>
    </div>
  );
};

export default EditSignatureForm;
